import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, Box, TextField, Select, MenuItem, Button, IconButton,
    Typography, CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import SuccessScreen from './SuccessScreen';
import ErrorManager from './ErrorManager';
import './modalStyle.css';

const WabaModal = ({ open, onClose }) => {
    const [canais, setCanais] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [canalSelecionado, setCanalSelecionado] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [templateIdSelecionado, setTemplateIdSelecionado] = useState('');
    const [dataHoraEnvio, setDataHoraEnvio] = useState('');
    const [titulo, setTitulo] = useState('');
    const [arquivoCsv, setArquivoCsv] = useState(null);
    const [arquivoCsvUrl, setArquivoCsvUrl] = useState('');
    const [entryInternationalPhone, setEntryInternationalPhone] = useState(false);
    const [adicionarEtiqueta, setAdicionarEtiqueta] = useState(true);
    const [contatosDuplicados, setContatosDuplicados] = useState(true);
    const [fecharChat, setFecharChat] = useState(true);
    const [email, setEmail] = useState('');
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);
    const [isCsvDragActive, setIsCsvDragActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { acontID } = useParams();
    const { token } = useParams();
    const timeZone = 'America/Sao_Paulo';

    const errorManagerRef = useRef(null);

    useEffect(() => {
        const fetchCanais = async () => {
            try {
                let canais = [];
                let page = 1;
                let lastPage = 1;

                do {
                    const response = await fetch(`https://homolog.qa.poli.digital/api/v1/customers/${acontID}/channels?page=${page}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Erro ao buscar canais`);
                    }

                    const data = await response.json();

                    const filteredCanais = data.data.filter(canal =>
                        canal.type === 'waba' &&
                        canal.channel_id === 6 &&
                        canal.connection === 1
                    );

                    canais = [...canais, ...filteredCanais];
                    lastPage = data.last_page;

                    page++;
                } while (page <= lastPage);

                setCanais(canais);
            } catch (error) {
                console.error('Erro ao buscar os canais');
                errorManagerRef.current.showError('Erro ao buscar canais');
            }
        };

        const fetchTemplates = async () => {
            try {
                const response = await fetch(`https://homolog.qa.poli.digital/api/v1/templates/${acontID}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`Erro ao buscar templates`);
                }
                const data = await response.json();
                setTemplates(data);
            } catch (error) {
                console.error('Erro ao buscar os templates:', error.message);
                errorManagerRef.current.showError('Erro ao buscar templates');
            }
        };

        if (open) {
            fetchCanais();
            fetchTemplates();
            const now = new Date();
            const zonedDate = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm");
            setDataHoraEnvio(zonedDate);
        }
    }, [open, acontID, token]);

    const fetchUsuarios = async (canalID) => {
        try {
            let usuarios = [];
            let currentPage = 1;
            let lastPage = 1;

            const firstResponse = await fetch(`https://disparador.qa.poli.digital/get-users/channels/${canalID}?perPage=150&page=${currentPage}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            });

            if (!firstResponse.ok) {
                throw new Error(`Erro ao buscar os usuários`);
            }

            const firstData = await firstResponse.json();
            usuarios = firstData.data;
            lastPage = firstData.last_page;

            setUsuarios(usuarios);

            if (currentPage < lastPage) {
                while (currentPage < lastPage) {
                    currentPage++;
                    const response = await fetch(`https://disparador.qa.poli.digital/get-users/channels/${canalID}?perPage=120&page=${currentPage}`, {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Erro ao buscar os usuários`);
                    }

                    const data = await response.json();
                    usuarios = [...usuarios, ...data.data];

                    setUsuarios(usuarios);
                }
            }
        } catch (error) {
            console.error('Erro ao buscar os usuários:', error);
            errorManagerRef.current.showError('Erro ao buscar os usuários');
        }
    };

    const handleCanalChange = (event) => {
        const selectedCanal = event.target.value;
        setCanalSelecionado(selectedCanal);
        setUsuarioSelecionado(''); // Reseta a seleção de usuário
        setUsuarios([]); // Limpa a lista de usuários

        if (selectedCanal) {
            fetchUsuarios(selectedCanal);
        }
    };

    const handleTemplateChange = (event) => {
        if (!canalSelecionado) {
            errorManagerRef.current.showError('Por favor, selecione um canal antes de escolher um template.');
            return;
        }
        setTemplateIdSelecionado(event.target.value);
    };

    const handleFileChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.type === 'text/csv') {
                setArquivoCsv(file);
                const url = await handleFileUpload(file);
                if (url) {
                    setArquivoCsvUrl(url);
                    setIsCsvUploaded(true);
                } else {
                    errorManagerRef.current.showError('Erro ao fazer upload do arquivo.');
                }
            } else {
                errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
            }
        }
    };

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);
        try {
            const response = await fetch(`https://disparador.qa.poli.digital/upload/customer/${acontID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                return data.fileUrl;
            } else {
                const errorText = await response.text();
                console.error(`Erro na requisição:`, errorText);
                errorManagerRef.current.showError('Erro no upload do arquivo: ' + errorText);
                return null;
            }
        } catch (error) {
            console.error('Erro ao enviar o arquivo:');
            errorManagerRef.current.showError('Erro ao enviar o arquivo');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!canalSelecionado || !dataHoraEnvio || !templateIdSelecionado || !arquivoCsvUrl || !email) {
            errorManagerRef.current.showError('Por favor, preencha todos os campos obrigatórios.');
            setIsSubmitting(false);
            return;
        }

        const uploadedFileUrl = await handleFileUpload(arquivoCsv);

        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('trigger_type', 'WABA');
        formData.append('acontID', acontID);
        formData.append('token', token);
        formData.append('userID', usuarioSelecionado);
        formData.append('channelID', canalSelecionado);
        formData.append('dataHoraEnvio', dataHoraEnvio);
        formData.append('templateIdSelecionado', templateIdSelecionado);
        formData.append('entry_link_file', uploadedFileUrl);
        formData.append('fechar_chat', fecharChat);
        formData.append('deduplicate_chat', contatosDuplicados);
        formData.append('create_tag', adicionarEtiqueta);
        formData.append('email', email);
        formData.append('entry_international_phone', entryInternationalPhone);

        try {
            const response = await fetch('https://hooks.zapier.com/hooks/catch/9726055/3vas2tx/', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Dados enviados com sucesso:', result);
                setShowSuccessScreen(true);
            } else {
                const errorText = await response.text();
                console.error(`Erro na requisição: ${response.status}`, errorText);
                errorManagerRef.current.showError(`Erro na requisição: ${response.status} - ${errorText}`);
            }
        } catch (error) {
            console.error('Erro ao enviar os dados');
            errorManagerRef.current.showError('Erro ao enviar os dados para Zapier');
        }
        setIsSubmitting(false);
    };

    const handleRemoveCsv = () => {
        setArquivoCsv(null);
        setArquivoCsvUrl('');
        setIsCsvUploaded(false);
    };

    const handleCsvDragOver = (event) => {
        event.preventDefault();
        setIsCsvDragActive(true);
    };

    const handleCsvDragLeave = () => {
        setIsCsvDragActive(false);
    };

    const handleCsvDrop = async (event) => {
        event.preventDefault();
        setIsCsvDragActive(false);
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            setArquivoCsv(file);
            const url = await handleFileUpload(file);
            if (url) {
                setArquivoCsvUrl(url);
                setIsCsvUploaded(true);
            } else {
                errorManagerRef.current.showError('Erro ao fazer upload do arquivo.');
            }
        } else {
            errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
        }
    };

    return (
        <>
            <ErrorManager ref={errorManagerRef} />
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal">
                    <form className="modal-content" onSubmit={handleFormSubmit}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h6">Criar campanha - WABA</Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Título do disparador"
                                margin="normal"
                                value={titulo}
                                onChange={(e) => setTitulo(e.target.value)}
                            />
                        </div>
                        <div className="input-container">
                            <Select
                                fullWidth
                                value={canalSelecionado}
                                onChange={handleCanalChange}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value="" disabled>Selecione o canal</MenuItem>
                                {canais.map(canal => (
                                    <MenuItem key={canal.id} value={canal.id}>{canal.name}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="input-container">
                            <Select
                                fullWidth
                                value={usuarioSelecionado}
                                onChange={(event) => setUsuarioSelecionado(event.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                                disabled={!canalSelecionado} // Desativa o campo até que o canal seja selecionado
                            >
                                <MenuItem value="" disabled>Selecione o usuário</MenuItem>
                                {usuarios.map(usuario => (
                                    <MenuItem key={usuario.user_id} value={usuario.user_id}>
                                        {usuario.user_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Data e horário de envio"
                                margin="normal"
                                type="datetime-local"
                                value={dataHoraEnvio}
                                onChange={(event) => setDataHoraEnvio(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <Select
                                fullWidth
                                value={templateIdSelecionado}
                                onChange={handleTemplateChange}
                                displayEmpty
                                margin="normal"
                                required
                                disabled={!canalSelecionado}  // Desativa a seleção de template até que o canal seja selecionado
                            >
                                <MenuItem value="" disabled>Template da mensagem</MenuItem>
                                {templates
                                    .filter(template => template.is_template && template.channel_id === canalSelecionado)
                                    .map(template => (
                                        <MenuItem key={template.tag} value={template.tag}>{template.tag}</MenuItem>
                                    ))}
                            </Select>
                        </div>
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.open('https://docs.google.com/spreadsheets/d/1wrbJz7rIPBi26xssXseUD8UQT5esdmh6LN3fNMzyEyo/copy', '_blank')}
                            >
                                Baixar Planilha Modelo
                            </Button>
                        </Box>
                        <div
                            className={`upload-container ${isCsvDragActive ? 'drag-active' : ''}`}
                            onDragOver={handleCsvDragOver}
                            onDragLeave={handleCsvDragLeave}
                            onDrop={handleCsvDrop}
                        >
                            <Button
                                variant="contained"
                                component="label"
                            >
                                {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Adicionar arquivo CSV'}
                                <input
                                    type="file"
                                    name="csvFile"
                                    accept=".csv"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>
                            {isCsvUploaded && (
                                <div className="file-preview">
                                    <a href={arquivoCsvUrl} download={arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}>
                                        {arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}
                                    </a>
                                    <div className="file-actions">
                                        <IconButton onClick={handleRemoveCsv} size="small" className="remove-button">
                                            <DeleteIcon />
                                        </IconButton>
                                        <CheckCircleIcon className="check-icon" />
                                    </div>
                                </div>
                            )}
                        </div>
                        <input type="hidden" name="arquivoCsvUrl" value={arquivoCsvUrl} required />
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, caso o cliente responda o bot será acionado e o contato poderá ser distribuído</Typography>
                            <Select
                                fullWidth
                                value={fecharChat}
                                onChange={(e) => setFecharChat(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, evitará que o contato receba duas mensagens</Typography>
                            <Select
                                fullWidth
                                value={contatosDuplicados}
                                onChange={(e) => setContatosDuplicados(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Se ativo vai adicionar etiqueta ao contato</Typography>
                            <Select
                                fullWidth
                                value={adicionarEtiqueta}
                                onChange={(e) => setAdicionarEtiqueta(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Caso verdadeiro não vai adicionar DDI 55 nos telefones que não tem</Typography>
                            <Select
                                fullWidth
                                value={entryInternationalPhone}
                                onChange={(e) => setEntryInternationalPhone(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="button-container">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}  // Desativa o botão enquanto isSubmitting for true
                            >
                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Enviar'}
                            </Button>
                        </div>
                    </form>
                    {showSuccessScreen && <SuccessScreen />} {/* Mostrar tela de sucesso */}
                </div>
            </Modal>
        </>
    );
};

export default WabaModal;