import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Modal, Select, FormControl, MenuItem, Pagination, IconButton, CircularProgress, Alert
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import myImage from '../assets/images/img.png';
import BookIcon from '@mui/icons-material/Book';
import BrokerModal from './BrokerModal';
import WabaModal from './WabaModal';
import TriggerDetailsModal from './TriggerDetailsModal';
import './Disparador.css';

const DisparadorContainer = styled(Box)({
  flexGrow: 1,
  padding: '20px',
});

const StatusButton = styled(Button)(({ status }) => ({
  backgroundColor: status === 'SUCESSO'
    ? '#28a745'
    : status === 'Erro'
      ? '#dc3545'
      : status === 'Disparando'
        ? '#ffc107'
        : '#17a2b8',
  color: '#fff',
  borderRadius: '12px',
  padding: '5px 10px',
  textTransform: 'none',
  width: '115px',
  height: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: status === 'SUCESSO'
      ? '#28a745'
      : status === 'Erro'
        ? '#dc3545'
        : status === 'Disparando'
          ? '#ffc107'
          : '#17a2b8',
  },
}));

const Disparador = () => {
  const { acontID, token } = useParams();
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [canais, setCanais] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [secondaryModalOpen, setSecondaryModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedTriggerDetails, setSelectedTriggerDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const today = new Date().toISOString().split('T')[0]; // Data atual no formato YYYY-MM-DD

  // Fetching Channels
  const fetchCanais = useCallback(async () => {
    try {
      let allData = [];
      let currentPage = 1;
      let lastPage = 1;

      do {
        const response = await fetch(`https://homolog.qa.poli.digital/api/v1/customers/${acontID}/channels?page=${currentPage}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Erro ao buscar os canais');
        }

        const data = await response.json();
        allData = allData.concat(data.data);

        lastPage = data.last_page;
        currentPage++;
      } while (currentPage <= lastPage);

      // Aplicar o filtro para selecionar apenas os canais desejados
      const filteredCanais = allData.filter(canal =>
        (canal.channel_id === 1 || canal.channel_id === 6) && // Filtra pelos IDs dos canais
        canal.connection === 1 // Filtra pela conexão
      );

      setCanais(filteredCanais); // Armazena os canais filtrados

    } catch (error) {
      console.error('Erro ao buscar os canais:', error);
      setError('Erro ao buscar os canais');
    }
  }, [acontID, token]);

  useEffect(() => {
    fetchCanais(); // Fetch the channels when the component mounts
  }, [fetchCanais]);

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate > today) {
      setError('A data inicial não pode ser maior que a data atual.');
    } else {
      setError('');
      setStartDate(selectedDate);

      if (endDate && selectedDate > endDate) {
        setEndDate('');
      }
    }
  };

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate < startDate) {
      setError('A data final não pode ser menor que a data inicial.');
    } else {
      setError('');
      setEndDate(selectedDate);
    }
  };

  const getStatus = (resulted) => {
    if (resulted === 'success') return 'SUCESSO';
    if (resulted === 'error') return 'Erro';
    if (resulted === null) return 'Disparando';
    if (resulted === 'Not Acceptable; customer complaint in progress. Você já possui um disparo em andamento, aguarde seu disparo finalizar') return 'Erro';
    return 'Erro';
  };

  useEffect(() => {
    if (!token) {
      console.error('Token is missing');
      return;
    }

    const fetchData = async (page) => {
      setIsLoading(true);
      try {
        const start = startDate ? `${startDate} 00:00:01` : '';
        const end = endDate ? `${endDate} 23:59:00` : '';

        const response = await axios.get(`https://disparador.qa.poli.digital/get-triggers/customer/${acontID}?perPage=20&page=${page}&start_date=${start}&end_date=${end}`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            'Content-Type': 'application/json',
          }
        });

        if (response.status !== 200) {
          console.error('Error fetching data:', response.statusText);
          return;
        }

        const data = response.data.data || [];
        setRows(data);
        setTotalPages(response.data.last_page);
        setIsEmpty(data.length === 0);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsEmpty(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData(currentPage);
  }, [acontID, token, currentPage, startDate, endDate]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCreateClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedChannel(''); // Reset the selected channel on close
  };

  const handleChannelChange = (event) => {
    setSelectedChannel(event.target.value);
  };

  const handleCreateTrigger = () => {
    setModalOpen(false);
    if (selectedChannel === 6) {
      setSecondaryModalOpen(true); // Open WABA modal
    } else if (selectedChannel === 1) {
      setSecondaryModalOpen(true); // Open Broker modal
    }
  };

  const closeSecondaryModal = () => {
    setSecondaryModalOpen(false);
  };

  const handleBookIconClick = (details) => {
    setSelectedTriggerDetails(details);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setSelectedTriggerDetails(null);
  };

  return (
    <DisparadorContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="subtitle1" color="textSecondary">
            Filtros por Data
          </Typography>
          <Box display="flex" gap={2}>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              max={today}
              style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate}
              style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
        <Button variant="contained" color="primary" onClick={handleCreateClick}>
          Criar nova campanha
        </Button>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
          <CircularProgress style={{ color: '#1976d2' }} />
        </Box>
      ) : (
        <>
          {isEmpty ? (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="70vh">
              <img src={myImage} alt="No Data" style={{ width: '500px', height: 'auto' }} />
              <Typography variant="h6" color="textSecondary" mt={2}>
                Não foram encontrados dados. Tente criar uma nova campanha.
              </Typography>
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Tag</strong></TableCell>
                      <TableCell><strong>Resultado</strong></TableCell>
                      <TableCell><strong>Canal</strong></TableCell>
                      <TableCell><strong>Data de envio</strong></TableCell>
                      <TableCell><strong>Responsável</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id} hover>
                        <TableCell>{row.trigger_identify}</TableCell>
                        <TableCell className="IconContainer">
                          <IconButton onClick={() => handleBookIconClick(row)}>
                            <BookIcon className="BookIcon" />
                          </IconButton>
                        </TableCell>
                        <TableCell>{row.channel_id}</TableCell>
                        <TableCell>{new Date(row.finished_at).toLocaleDateString()}</TableCell>
                        <TableCell>{row.user_id || 'N/A'}</TableCell>
                        <TableCell>
                          <StatusButton status={getStatus(row.resulted)} className={`StatusButton ${getStatus(row.resulted).toUpperCase()}`}>
                            {getStatus(row.resulted)}
                          </StatusButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </>
          )}
        </>
      )}

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-channel-selection"
        aria-describedby="modal-channel-selection-description"
      >
        <div className="modal">
          <div className="modal-content">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography id="modal-channel-selection" variant="h6" component="h2">
                Selecionar Canal
              </Typography>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <FormControl fullWidth margin="normal">
              <Select
                fullWidth
                value={selectedChannel}
                onChange={handleChannelChange}
                displayEmpty
              >
                <MenuItem value="" disabled>Selecione o Canal</MenuItem>
                {canais.map((canal) => (
                  <MenuItem key={canal.id} value={canal.channel_id}>
                    {canal.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateTrigger}
                disabled={!selectedChannel}
              >
                Criar
              </Button>
            </Box>
          </div>
        </div>
      </Modal>

      {selectedChannel === 6 && (
        <WabaModal open={secondaryModalOpen} onClose={closeSecondaryModal} channelId={selectedChannel} />
      )}
      {selectedChannel === 1 && (
        <BrokerModal open={secondaryModalOpen} onClose={closeSecondaryModal} channelId={selectedChannel} />
      )}

      <TriggerDetailsModal
        open={detailsModalOpen}
        onClose={closeDetailsModal}
        details={selectedTriggerDetails}
      />
    </DisparadorContainer>
  );
};

export default Disparador;
