import React from 'react';
import { Snackbar, Alert } from '@mui/material';

class ErrorManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: '',
        };
    }

    showError(message) {
        this.setState({ open: true, message });
        setTimeout(() => {
            this.setState({ open: false });
        }, 10000);
    }

    render() {
        return (
            <Snackbar
                open={this.state.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => this.setState({ open: false })}
            >
                <Alert severity="error" onClose={() => this.setState({ open: false })}>
                    {this.state.message}
                </Alert>
            </Snackbar>
        );
    }
}

export default ErrorManager;
